<template>
	<div
		class="absolute top-[calc(50%_-_29px)] z-10 hidden cursor-pointer rounded-full bg-zinc-900/90 p-4 transition duration-200 ease-in-out lg:flex"
		:class="direction === 'left' ? '-left-3' : '-right-3'"
		:style="{ opacity: isVisible ? 1 : 0 }"
		@click="emit('scroll', direction)"
	>
		<ChevronLeftIcon
			class="h-6 w-6 stroke-white stroke-2"
			:class="{ 'rotate-180': direction === 'right' }"
		/>
	</div>
</template>

<script setup lang="ts">
import ChevronLeftIcon from '@heroicons/vue/24/outline/ChevronLeftIcon';

defineProps({
	isVisible: { type: Boolean, default: true },
	direction: {
		type: String,
		default: 'left',
		validator: (value: string) => ['left', 'right'].includes(value),
	},
});

const emit = defineEmits(['scroll']);
</script>
