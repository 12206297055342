import { ref } from 'vue';

export default function useCarouselNavigation(containerRef: Ref<HTMLDivElement | null>) {
	const showLeftArrow = ref(false);
	const showRightArrow = ref(true);

	const slide = (direction: string, cardsToScroll: number) => {
		if (!containerRef.value) return;

		const scrollAmount = containerRef.value.scrollWidth / cardsToScroll; // Card width

		// Determine the direction and scroll
		const scrollValue = direction === 'left' ? -scrollAmount : scrollAmount;
		containerRef.value.scrollBy({
			top: 0,
			left: scrollValue,
			behavior: 'smooth',
		});
	};

	function checkArrows() {
		if (!containerRef.value) return;

		const container = containerRef.value;
		const newScrollLeft = container.scrollLeft;
		const width = container.offsetWidth;
		const scrollWidth = container.scrollWidth;

		showLeftArrow.value = newScrollLeft !== 0;
		showRightArrow.value = scrollWidth - newScrollLeft > width + 100;
	}

	return {
		showLeftArrow,
		showRightArrow,
		slide,
		checkArrows,
	};
}
